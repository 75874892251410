import CertificateInfoModel from "@/_approval/components/statement/write/CertificateInfoModel";
import PurchaseRequestWfModel from "@/_approval/components/statement/write/PurchaseRequestWfModel";
import ApprovalLineModel from "@/_approval/pages/purchaseDraft/write/ApprovalLineModel";
import {
  accountType,
  aprvlLineType,
  assetType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";

class SettlementDataModel {
  constructor() {
    this.certificateList = [];
    this.isModifyMode = false;
    //this.certificateList.push(new CertificateInfoModel());
    this.empNo = "";
    this.logId = "";
    this.email = "";

    // 구매요청
    this.purchaseRequestWf = new PurchaseRequestWfModel();
    this.settlementHistories = []; // 등록 정산서

    this.settlementCid = null; // 정산서 번호
    this.settlementName = ""; // 정산서명
    this.settlementType = ""; // 정산서 타입 (지출 || 인프라)
    this.status = "";
    this.voucherKey = ""; //기안번호
    // this.statusCode = ''; 등록 데이터에는 존재하지않아서 주석처리
    // this.statusCodeValue = 0;

    /**
     * 문서 상태
     * @type {docStatus}
     * @private
     */
    this._docStatus = null;
    /**
     * 구매요청부서
     * @type {string}
     * @private
     */
    this._deptPathName = "";
    /**
     * 구매요청 부서코드
     * @type {string}
     * @private
     */
    this._deptCode = "";
    /**
     * 구매요청 부서명
     * @type {string}
     * @private
     */
    this._deptName = "";
    /**
     * 결제선 지정 - 계정선택
     * name: 계정명, code: 계정아이디
     * @type {*[]}
     * @private
     */
    this._approvalLineAccounts = null;

    /**
     * 발제 부서라인
     * @type {*[]}
     * @private
     */
    this._draftDeptApprLine = [];
    /**
     * 승인자 처리라인
     * @type {*[]}
     * @private
     */
    this._processingApprovalLine = [];
    /**
     * 참조자
     * @type {*[]}
     * @private
     */
    this._referenceApprovalLine = [];
    /**
     * 나의 발제 부서 정보
     * 발제 부서 라인에  나의 정보는 빠져있으므로 생성시 같이 사용함.
     * @type {ApprovalLineModel}
     * @private
     */
    this._myDraftDeptInfo = null;
    /**
     * 이메일
     * @type {string}
     * @private
     */
    this._email = "";
    /**
     * 모바일 번호
     * @type {string}
     * @private
     */
    this._mobile = "";
    /**
     * 등록일
     * @type {string}
     * @private
     */
    this._regDate = "";
    /**
     * 기안자명
     * @type {string}
     * @private
     */
    this._drftEmpName = "";
    /**
     * 공개 여부
     * @type {boolean}
     * @private
     */
    this._publicYn = false;
    /**
     * 반려 전 기존 상신된 문서 PK
     * @type {string}
     * @private
     */
    this._reDrftOriDocNo = "";
    this._apprType = ""; // 승인타입 F 최종 / M 중간
    // 기간인식 여부
    this._hasPeriod = false;
    // 원천세 하위분류 데이터 여부
    this._isSubdivisionData = false;
  }

  setCertificateList(itemList) {
    if (itemList) {
      const itemModel = new CertificateInfoModel();
      itemModel.setData(itemList);

      //this.certificateList.push(itemModel);
      return itemModel;
    } else {
      this.certificateList.push(new CertificateInfoModel());
    }
  }

  setData(obj) {
    if (obj.certificateList) {
      this.certificateList = obj.certificateList.map((item) => {
        const itemModel = new CertificateInfoModel();
        itemModel.setData(item);
        return itemModel;
      });
    }
    this.empNo = obj.empNo || "";
    this.purchaseRequestWf = obj.purchaseRequestWf || {};
    this.settlementHistories = obj.settlementHistories.history || []; // 등록 정산서
    this.settlementCid = obj.settlementCid || null; // 정산서 번호
    this.settlementName = obj.settlementName || ""; // 정산서명
    this.settlementType = obj.settlementType || ""; // 정산서 타입 (지출 || 인프라)
    this.status = obj.status || "";
    this.statusCode = obj.statusCode || "";
    this.statusCodeValue = obj.statusCodeValue || 0;
    this.voucherKey = obj.voucherKey || ""; // 기안번호
  }

  initFromFetchData(data) {
    const detailData = data.data;

    this._approvalLineAccounts = { code: data.aprvlLineAccntNo };
    this._targetPk = data.targetPk;
    this._regDate = data.regDate;
    this._drftEmpName = data.drftEmpName;
    this._deptName = data.drftDeptName;
    this._publicYn = data.publicYn;

    //this._reDrftOriDocNo = detailData.reDrftOriDocNo;
    this._deptCode = detailData.deptCode;
    this._deptPathName = detailData.deptPathName;
    this._email = detailData.email;
    this._mobile = detailData.mobile;

    this.setData(detailData);
  }

  getData() {
    let obj = {
      empNo: this.empNo,
      purchaseRequestWf: this.purchaseRequestWf,
      settlementHistories: this.settlementHistories,
      settlementCid: this.settlementCid,
      settlementName: this.settlementName,
      settlementType: this.settlementType,
      status: this.status,
      statusCode: this.statusCode,
      statusCodeValue: this.statusCodeValue,
      voucherKey: this.voucherKey,
    };
    const ret = this.certificateList.map((item) => item.getData());

    obj.certificateList = ret;

    return obj;
  }

  /**
   * 유효성 검사 체크
   * 임시 저장 시에는 정산서명, 구매요청 연결 여부만 체크
   * @return {string|undefined}
   */
  validateForTemp() {
    if (!this.settlementName) {
      return "정산서명을 입력 해주세요.";
    }
    if (this.purchaseRequestWf.requestCid == "") {
      return "구매요청 정보를 등록 해주세요.";
    }

    return void 0;
  }

  /**
   * 유효성 검사 체크
   * - 발제 부서 라인의 경우 필수이지만 기본으로 자신이 포함되어 있으므로 유효성 검사에서 제외한다.
   * @return {string|undefined}
   * 인프라여부, 승인할문서여부, 임시저장여부
   */
  validate(isInfra, isReceiveDetailWrite, isTemp) {
    if (!isReceiveDetailWrite) {
      // 임시저장시 벨리데이션 항목 변경 - 추후 적용
      // if(!isTemp){
      // if (!this._approvalLineAccounts) {
      //   return this.showError('결재선을 선택 해주세요.', '');
      // }
      // if (!this._deptCode) {
      //   return this.showError('기안자부서를 선택 해주세요.', '');
      // }
      // if (!this._processingApprovalLine || this._processingApprovalLine.length === 0) {
      //   return this.showError('승인자 처리 라인을 선택해주세요.', '');
      // }
      // }else{
      if (!this.settlementName) {
        return this.showError("정산서명을 입력 해주세요.", "settlementName");
      }
      if (this.purchaseRequestWf.requestCid == "") {
        return this.showError("구매요청 정보를 등록 해주세요.", "");
      }
      // }
    }
    // if(!isTemp){
    let errorMsg, errorKey, certificateIdx, voucherItemIdx;

    for (var i = 0; i < this.certificateList.length; i++) {
      const item = this.certificateList[i];
      certificateIdx = i;
      if (item.certificateDesc == "") {
        return this.showError(
          "정산 정보명을 입력 해주세요.",
          errorKey,
          certificateIdx,
          voucherItemIdx,
        );
      }

      const proofType = item.proofType;
      errorMsg = item.payment.getAlertMessage(proofType);

      if (item.proofDate == null || item.proofDate == "") {
        return this.showError(
          "증빙일자를 입력 해주세요.",
          errorKey,
          certificateIdx,
          voucherItemIdx,
        );
      }

      const currency = item.payment.currency;
      if (currency == "") {
        return this.showError(
          "통화코드를 선택 해주세요.",
          errorKey,
          certificateIdx,
          voucherItemIdx,
        );
      }

      const supplyPrice = item.payment.supplyPrice;
      if (supplyPrice == "") {
        return this.showError(
          "공급가액을 입력 해주세요.",
          errorKey,
          certificateIdx,
          voucherItemIdx,
        );
      }

      const companyName = item.partnerName;
      if (companyName == "") {
        return this.showError("지급처를 선택 해주세요.", errorKey, certificateIdx, voucherItemIdx);
      }

      if (item.voucher.voucherDate == null || item.voucher.voucherDate == "") {
        return this.showError(
          "전표일자를 입력 해주세요.",
          errorKey,
          certificateIdx,
          voucherItemIdx,
        );
      }
      if (item.voucher.proofDate == null || item.voucher.proofDate == "") {
        return this.showError(
          "전표의 증빙일자를 입력 해주세요.",
          errorKey,
          certificateIdx,
          voucherItemIdx,
        );
      }
      if (item.voucher.expenditureDate == "") {
        return this.showError(
          "지급예정일을 입력 해주세요.",
          errorKey,
          certificateIdx,
          voucherItemIdx,
        );
      }
      if (item.voucher.erpPartnerName == "") {
        return this.showError("지급처를 선택 해주세요.", errorKey, certificateIdx, voucherItemIdx);
      }

      //if(item.voucher.voucherItems.length > 0 ) {
      for (var l = 0; l < item.voucher.voucherItems.length; l++) {
        voucherItemIdx = l;
        const voucherItem = item.voucher.voucherItems[l];
        if (voucherItem.accountName == "" && voucherItem.surtaxYn === "false") {
          return this.showError(
            "계정과목을 선택 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (voucherItem.deptName == "") {
          return this.showError("부서를 선택 해주세요.", errorKey, certificateIdx, voucherItemIdx);
        }
        //if(voucherItem.goodsName == '') {
        //   return this.showError( '자산명을 입력 해주세요.', errorKey, certificateIdx, voucherItemIdx );
        // }

        if (isInfra) {
          if (voucherItem.newUsed == "") {
            return this.showError(
              "구분을 입력 해주세요.",
              errorKey,
              certificateIdx,
              voucherItemIdx,
            );
          }
          if (voucherItem.debit == "") {
            return this.showError(
              "금액을 입력 해주세요.",
              errorKey,
              certificateIdx,
              voucherItemIdx,
            );
          }
        }
        // 자산전표일경우 자산항목
        else if (voucherItem.infraAccountYn) {
          if (voucherItem.newUsed == "") {
            return this.showError(
              "구분을 입력 해주세요.",
              errorKey,
              certificateIdx,
              voucherItemIdx,
            );
          }
          if (voucherItem.goodsName == "") {
            return this.showError(
              "자산명을 입력 해주세요.",
              errorKey,
              certificateIdx,
              voucherItemIdx,
            );
          }
          if (voucherItem.assetMainClassification == "") {
            return this.showError(
              "자산분류를 선택 해주세요.",
              errorKey,
              certificateIdx,
              voucherItemIdx,
            );
          }
          if (voucherItem.amount == "") {
            return this.showError(
              "자산수량을 입력 해주세요.",
              errorKey,
              certificateIdx,
              voucherItemIdx,
            );
          }
        }
      }
      // 기간인식
      if (this._hasPeriod) {
        const voucher = item.voucher;
        if (voucher.periodStartDate == "") {
          return this.showError(
            "기간인식 시작일을 입력 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (voucher.periodEndDate == "") {
          return this.showError(
            "기간인식 종료일을 입력 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (voucher.incomeLossAccountCode == "") {
          return this.showError(
            "기간인식 손익계정과목을 입력 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (voucher.incomeLossTaxItem == "") {
          return this.showError(
            "기간인식 세목을 입력 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
      }
      // 원천세
      if (proofType == "WT") {
        const withholdingTax = item.voucher.withholdingTax;
        if (withholdingTax.withholdingTaxCode.withholdingTaxCode == "") {
          return this.showError(
            "원천세 세금유형을 선택 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (this._isSubdivisionData && withholdingTax.withholdingTaxCode.subdivisionCode == "") {
          return this.showError(
            "원천세 하위분류를 선택 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (withholdingTax.email.name == "") {
          return this.showError(
            "원천세 이메일을 입력 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (withholdingTax.email.domain == "") {
          return this.showError(
            "원천세 이메일 도메인을 입력 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
      }
      // 부가세
      if (this._apprType == "F" && proofType != "WT") {
        const surtax = item.voucher.surtax;
        if (surtax.surtaxCode == "") {
          return this.showError(
            "부가세 세금유형을 선택 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
        if (surtax.numberOfVat == null) {
          return this.showError(
            "처리 건수를 입력 해주세요.",
            errorKey,
            certificateIdx,
            voucherItemIdx,
          );
        }
      }
    }
    // }
  }

  showError(errorMsg, errorKey, certificateIdx, voucherItemIdx) {
    if (errorMsg) {
      return {
        errorMsg: errorMsg,
        errorKey: errorKey,
        certificateIdx: certificateIdx,
        voucherItemIdx: voucherItemIdx,
      };
    }
    return void 0;
  }

  makeFormData() {
    return {
      docStatus: this._docStatus,
      //aprvlLineAccntNo: this._approvalLineAccounts.code,
      reDrftOriDocNo: "",
      targetGubun: targetGubun.DF_SETTLEMENT,
      publicYn: false,
      //aprvlLineList: this._makeAprvlLineList(),
      data: this._makeDraftDetailData(),
    };
  }

  _makeDraftDetailData() {
    return {
      email: this.email,
      loginId: this.loginId,
      empNo: this.empNo,
      purchaseRequestWf: this.purchaseRequestWf,
      settlementCid: this.settlementCid,
      settlementName: this.settlementName,
      //statementType: this.statementType,
      //settlementType: this.settlementType ,
      status: this.status,
      statusCode: this.statusCode,
      statusCodeValue: this.statusCodeValue,
      certificateList: this.certificateList.map((e) => e.getData()),
    };
  }

  _makeAprvlLineList() {
    const approvalLineList = [];
    approvalLineList.push(...this._makeDraftDeptApprovalLineList());
    approvalLineList.push(...this._makeProcessingApprovalLine());
    approvalLineList.push(...this._makeReferenceApprovalLine());

    return approvalLineList.map((e, index) => e.toJson(index + 1));
  }

  _makeReferenceApprovalLine() {
    if (!this._referenceApprovalLine || this._referenceApprovalLine.length === 0) {
      return [];
    }

    return this._referenceApprovalLine.map(
      (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.REFERENCE),
    );
  }

  _makeProcessingApprovalLine() {
    return this._processingApprovalLine.map(
      (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.APPROVAL),
    );
  }

  _makeDraftDeptApprovalLineList() {
    const approvalLine = [];
    if (this._myDraftDeptInfo) {
      approvalLine.push(this._myDraftDeptInfo);
    }
    if (this._draftDeptApprLine && this._draftDeptApprLine.length > 0) {
      const otherLine = this._draftDeptApprLine.map(
        (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.APPROVAL, "", true),
      );

      approvalLine.push(...otherLine);
    }

    return approvalLine;
  }

  set deptPathName(depthPathName) {
    this._deptPathName = depthPathName;
  }
  set deptCode(deptCode) {
    this._deptCode = deptCode;
  }
  set draftDeptApprLine(draftDeptApprLine) {
    this._draftDeptApprLine = draftDeptApprLine;
  }
  set processingApprovalLine(value) {
    this._processingApprovalLine = value;
  }
  set referenceApprovalLine(value) {
    this._referenceApprovalLine = value;
  }
  set approvalLineAccounts(value) {
    this._approvalLineAccounts = value;
  }
  set docStatus(value) {
    this._docStatus = value;
  }
  set myDraftDeptInfo(value) {
    this._myDraftDeptInfo = value;
  }
  set deptName(value) {
    this._deptName = value;
  }
  set email(value) {
    this._email = value;
  }
  set mobile(value) {
    this._mobile = value;
  }
  set regDate(value) {
    this._regDate = value;
  }

  get deptPathName() {
    return this._deptPathName;
  }
  get regDate() {
    return this._regDate;
  }
}

export default SettlementDataModel;
