import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import ApiService from "@/services/ApiService";
import { commConst, approvalStatus, targetGubun, aprvlLineType } from "@/_approval/const/const";
import { stringify } from "query-string";
import commDocs from "@/_approval/mixins/common/commDocs";

import SettlementDataModel from "@/_approval/components/statement/write/SettlementDataModel";

const StatementDetailMixin = {
  mixins: [CommLayerMixin, CommToastMixin, commDocs],
  data() {
    return {
      docOpenYn: "docOpenY",
      approvalLine: [],
      payAccountId: "",
      //docNo: this.$route.query.docNo,
      draftFlag: "",
      // remark: '',
      // paymentList: [],
      // invoiceList: [],
      // rejectPaymentIdList: [],
      viewerList: [],
      memberList: [],
      addApproverList: [],
      spliceAddIndex: null,
      spliceAddIndexFromAll: null,
      layerAfterAlertMsg: "",
      // apprReason: '',
      // subject: '',
      // mileageFlag: false,
      // assetApply: {},
      settlementData: new SettlementDataModel(),
      regDate: "",
      // settlementData:{
      //   certificateList: [
      //     {
      //       // 세금계산서/계산서 자료
      //       billFiles: [
      //         {
      //           attachFile: {
      //             attachExt: "pptx",
      //             attachNum: 1,
      //             attachType: "pptx",
      //             orgFile: "dktechin_구매(입찰)시스템_v0.1.8_20190710_2.pptx",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "세금계산서 영수증"
      //         }
      //       ],
      //       certificateCid: "string",
      //       certificateDesc: "지출내용(Default 구매요청)", // 정산 내용
      //       certificateId: 124, // 정산 번호
      //       // 계약정보
      //       contract: {
      //         commonReqName: "string", // 계약요청자
      //         contractId: 0, // 계약서 번호
      //         contractStartDate: "string", // 계약시작일
      //         description: "카카오, 카카오엠 서비스 관리 시스템 계약정보" // 계약명
      //       },
      //       // 검수
      //       contractInspect: {
      //         calculateNum: 1,
      //         deliveryCompleteDate: 20191103, // 납품완료일
      //         deliveryHistory: "아이폰 10", // 납품 내역
      //         description: "내역", // 설명 (납품내역)
      //         etc: "특이사항", // 특이사항
      //         inspectCompleteDate: 20191103, // 검수완료일
      //         inspectFile: {
      //           attachExt: "pptx",
      //           attachNum: 1,
      //           attachType: "pptx",
      //           orgFile: "dktechin_구매(입찰)시스템_v0.1.8_20190710_2.pptx",
      //           sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //         },
      //         inspectNum: 1, // 검수번호
      //         inspectPrice: 8888888, // 검수 요청 가격
      //         inspectYn: false, // 검수 필요여부
      //         partnerInspectCompleteDate: 20210101, // 파트너 검수 완료일
      //         partnerInspectOpinion: "갑질근절",
      //         regDate: 20191103231022, // 등록 날짜
      //         regId: "justin.koo", // 등록 아이디
      //         stateCode: "?" // 검수상태 코드
      //       },
      //       // 기타 증빙자료
      //       etcFiles: [
      //         {
      //           attachFile: {
      //             attachExt: "pdf",
      //             attachNum: 1,
      //             attachType: "pdf",
      //             orgFile: "일이삼사오육칠.pdf",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "말줄임테스트 7글자"
      //         },
      //         {
      //           attachFile: {
      //             attachExt: "png",
      //             attachNum: 1,
      //             attachType: "png",
      //             orgFile: "일이삼사오육칠팔구십.png",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "말줄임테스트 10글자"
      //         },
      //         {
      //           attachFile: {
      //             attachExt: "jpg",
      //             attachNum: 1,
      //             attachType: "jpg",
      //             orgFile: "일이삼사오육칠팔구십일이삼사오육칠팔구십.jpg",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "말줄임테스트 20글자"
      //         },
      //         {
      //           attachFile: {
      //             attachExt: "zip",
      //             attachNum: 1,
      //             attachType: "zip",
      //             orgFile: "일이삼사오육칠팔구십일이삼사오육칠팔구십일이.zip",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "말줄임테스트 이십이글자"
      //         },
      //         {
      //           attachFile: {
      //             attachExt: "xls",
      //             attachNum: 1,
      //             attachType: "xls",
      //             orgFile: "일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오.xls",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "말줄임테스트 25글자"
      //         },
      //         {
      //           attachFile: {
      //             attachExt: "sketch",
      //             attachNum: 1,
      //             attachType: "sketch",
      //             orgFile: "일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십.sketch",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "말줄임테스트 30글자"
      //         },
      //         {
      //           attachFile: {
      //             attachExt: "sketch",
      //             attachNum: 1,
      //             attachType: "sketch",
      //             orgFile: "k-pick_KEP 구매입찰시스템_Web_디자인_210126(f).sketch",
      //             sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //           },
      //           attacheId: 0,
      //           comments: "말줄임테스트 30글자"
      //         }
      //       ],
      //       // 발주
      //       order: {
      //         orderCid: "V001QGIJXGTP22", // 발주 일련번호
      //         orderDate: 20190723150000, // 발주 날짜
      //         status: "Y", // 발주 상태
      //         title: "카카오, 카카오엠 서비스 관리 시스템 발주정보" // 발주서 명
      //       },
      //       orderNum: 1, // 순서
      //       // 지급처
      //       partner: {
      //         // 통장사본
      //         bankBookFile: {
      //           attachExt: "png",
      //           attachNum: 1,
      //           attachType: "png",
      //           orgFile: "일이삼사오육칠팔구십일이삼사오육칠팔구잇일.png",
      //           sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //         },
      //         // 사업자 등록증 사본
      //         businessRegFile: {
      //           attachExt: "pdf",
      //           attachNum: 1,
      //           attachType: "pdf",
      //           orgFile: "dktechin_구매(입찰)시스템_v0.1.8_20190710_2일이삼사오육칠팔구십.pdf",
      //           sysFilePath: "bOFrJ7/ZSd5mqHmtx/4qt1S0hOTqYeTAiUs3IgFK"
      //         },
      //         companyName: "디케이 테크인", // 회사 이름
      //         corporateNum: "1XX-8X-4XXXX", // 사업자 번호
      //         userNum: 0 // 사용자 번호
      //       },
      //       payment: {
      //         certificateId: 0, // 증빙번호
      //         currency: "KRW", // 통화
      //         expenditureDate: "string", // 지급예정일
      //         modReason: "지급예정일 수정사유", // 수정사유
      //         paymentCalculateList: [ // 정산내역
      //           {
      //             calculateId: 0,
      //             draftCid: "string", // 기안 일련번호
      //             inspectCompleteDate: "string", // 검수 날짜
      //             inspectPrice: "string", // 검수 가격
      //             paymentCid: "string" // 결제내역번호
      //           }
      //         ],
      //         paymentCid: "string", // 결제내역번호
      //         paymentType: "AT", // 결제수단
      //         supplyPrice: "18,000,000", // 공급가액
      //         taxAmount: "2,000,000", // 세액(공급사액 10%)
      //         totalAmount: "20,000,000", // 총액(공급가액 + 세액)
      //         totalInspectPrice: "string" // 검수금액 합
      //       },
      //       proofDate: "", // 증빙일자
      //       proofName: "원천세", // 증빙명
      //       proofNum: "string", // 증빙번호(국세청 증빙번호)
      //       proofType: "WT", // 증빙유형
      //       settlementCid: "K001P201200001", // 정산서 번호
      //       // 전표
      //       voucher: {
      //         certificateId: 1234, // 증빙번호
      //         currency: "KRW", // 통화
      //         erpPartnerCode: "CCD", // 거래처코드(ERP)
      //         erpPartnerName: "주식회사 링키지랩", // 거래처(ERP)
      //         erpVoucherNo: 15124512, // ERP 전표번호
      //         exchangeRate: 12, // 환율
      //         exchangeRateDate: "2020-12-12", // 환율기준일
      //         expenditureDate: "2021-01-14", // 지급예정일
      //         expenditureName: "주식회사 디케이테크인",
      //         proofDate: "string", // 증빙일자
      //         regDate: 20210114000000, // 등록일자
      //         status: "W", // ERP 처리상태 (W:전표처리중, S:전표생성완료, F:전표생성실패)
      //         // 부가세
      //         surtax: {
      //           electroWrite: "E", // 전자 E: ELECT , 수기 W: WRITE
      //           exclusionOfDeductionReason: "불공제사유 내용이 들어갑니다", // 불공제사유
      //           receiptBill: "R", // 영수:R/청구:D
      //           taxCode: "A", // 세금유형
      //           vatAssetType: "A", // 자산구분
      //           voucherCid: "string" // 전표 번호
      //         },
      //         voucherCid: "string", // 전표 번호
      //         voucherDate: 20201212000000, // 전표일자
      //         voucherItems: [
      //           {
      //             accountCode: "string", // 계정과목
      //             accountName: "비용/자산계정", // 계정과목명
      //             amount: "100", // 수량
      //             assetsCategory: "string", // 자산분류코드
      //             assetsCategoryName: "string", // 인프라 자산분류(서버, 기계장치, 네트워크)
      //             assetsGubun: "신규/중고", // 자산구분(신규/중고)
      //             barcode: "1111111111111", // 인프라 바코드번호
      //             credit: "6,000,000", // 대변
      //             debit: "6,000.000", // 차변
      //             deptCode: "string", // 부서코드
      //             deptName: "경영기획실", // 부서명
      //             goodsName: "자산명이 노출됩니다", // 자산명
      //             note: "string", // 비고
      //             orderNum: 1, // 전표순서
      //             price: "1,000,000", // 가격
      //             serviceCode: "string", // 서비스코드
      //             serviceName: "음성처리엔진", // 서비스
      //             taxItem: "string", // 세목
      //             taxName: "용역(개발/디자인)", // 세목명
      //             voucherCid: "string", // 전표번호
      //             voucherItemId: 0 // 전표항목번호
      //           },
      //           {
      //             accountCode: "string", // 계정과목
      //             accountName: "비용/자산계정", // 계정과목명
      //             amount: "100", // 수량
      //             assetsCategory: "string", // 자산분류코드
      //             assetsCategoryName: "string", // 인프라 자산분류(서버, 기계장치, 네트워크)
      //             assetsGubun: "신규/중고", // 자산구분(신규/중고)
      //             barcode: "1111111111111", // 인프라 바코드번호
      //             credit: "6,000,000", // 대변
      //             debit: "6,000.000", // 차변
      //             deptCode: "string", // 부서코드
      //             deptName: "경영기획실", // 부서명
      //             goodsName: "자산명이 노출됩니다", // 자산명
      //             note: "string", // 비고
      //             orderNum: 1, // 전표순서
      //             price: "1,000,000", // 가격
      //             serviceCode: "string", // 서비스코드
      //             serviceName: "음성처리엔진", // 서비스
      //             taxItem: "string", // 세목
      //             taxName: "용역(개발/디자인)", // 세목명
      //             voucherCid: "string", // 전표번호
      //             voucherItemId: 0 // 전표항목번호
      //           }
      //         ],
      //         voucherProofDate: 20201212000000, // 증빙일자
      //         voucherSaveModeYn: false, // 전표 생성 여부
      //         // 원천세
      //         withholdingTax: {
      //           businessPlaces: "사업장", // 사업장
      //           email: "kerry.youbo@daum.net", // 이메일
      //           subdivisionCode: "A", // 하위분류
      //           taxBurdenYn: "Y", // 세액회사부담여부
      //           taxCode: "A", // 세금유형
      //           voucherCid: "string" // 전표 번호
      //         }
      //       }
      //     },
      //   ],
      //   empNo: "string",
      //   // 구매요청
      //   purchaseRequestWf: {
      //     name: "홍길동", // 구매요청자명
      //     regDate: "2020-12-24", // 구매요청등록일
      //     requestCid: "string", // 구매요청 기안번호
      //     title: "카카오 서비스 계약관리 시스템 구매요청서" // 제목
      //   },
      //   settlementCid: "K001P201200001", // 정산서 번호
      //   settlementName: "지출정산서 임시 테스트 데이터 명", // 정산서명
      //   statementType: "OL", // 정산서 타입 (OL(지출)/IF(인프라))
      //   status: "string",
      //   statusCode: "100 CONTINUE",
      //   statusCodeValue: 0,
      // },
      // inspectHistory:[
      //   {"regDate":"20200122114047","stateCode":"INITIAL","etc":"","regId":"justin.koo.ke","contractId":17,"calculateNum":39,"inspectCompleteDate":"","deliveryCompleteDate":"","deliveryHistory":"","stateCodeName":"납품대기"},
      //   {"regDate":"20200204083117","stateCode":"REQUESTER_INSPECT","etc":"","regId":"kerry.youbo.ke","contractId":17,"calculateNum":39,"inspectCompleteDate":"","deliveryCompleteDate":"","deliveryHistory":"","inspectFile":{"attachExt":"png","attachNum":234,"attachType":"","orgFile":"일이삼사오육칠팔구십.png","sysFilePath":"495642374f2f5a5366646b79774a74582f53614a747558653454776430516578756b5451356430"},"stateCodeName":"현업검수"},
      //   {"regDate":"20200331135053","stateCode":"PURCHASE_INSPECT","etc":"","regId":"ella.y.ke","contractId":17,"calculateNum":39,"inspectCompleteDate":"","deliveryCompleteDate":"","deliveryHistory":"","stateCodeName":"구매팀검수"},
      //   {"regDate":"20200331135053","stateCode":"PURCHASE_CONFIRM","etc":"","regId":"system","contractId":17,"calculateNum":39,"inspectCompleteDate":"","deliveryCompleteDate":"","deliveryHistory":"","stateCodeName":"정산대기"},
      //   {"regDate":"20200401100018","stateCode":"COMPLETE","etc":"","regId":"batch","contractId":17,"calculateNum":39,"inspectCompleteDate":"","deliveryCompleteDate":"","deliveryHistory":"","stateCodeName":"정산완료"}
      // ],

      // comment:'',

      isPopStatementVoucher: false,
      isPopInfraVoucher: false,

      DOC_PUBLIC_RADIO_LIST: [
        { code: "Y", name: "공개" },
        { code: "N", name: "비공개" },
      ],
      selectedRadioId: "Y",
    };
  },
  watch: {
    // layerAfterAlertMsg() {
    //   if (this.layerAfterAlertMsg != '') {
    //     this._showLayer({ type: 'alert', content: this.layerAfterAlertMsg });
    //     this.layerAfterAlertMsg = '';
    //   }
    // },
    // approvalLine() {
    //   this.getViewerList();
    //   this.getAddApproverList();
    // },
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
    isInfraStatement() {
      return this.settlementData.settlementType === "IF";
    },
    // rejectYn: function () {
    //   let rejectYn = false;
    //   this.approvalLine.forEach((approval) => {
    //     if (approval.apprReason != null && approval.apprReason !== '') {
    //       // 승인/반려 코멘트가 있는 경우
    //       rejectYn = true;
    //     }
    //   });
    //   return rejectYn;
    // },
    // dateTimeFormat: function () {
    //   return commConst.DATETIME_FORMAT;
    // },
    // isApproval() {
    //   let isApproval = false;
    //   this.approvalLine.some((approval) => {
    //     if (approval.empNo === this.login.empNo) {
    //       isApproval = true;
    //       return true;
    //     }
    //   });
    //   return isApproval;
    // },
    // whetherExistComment() {
    //   return this.approvalLine.some(
    //     (e) => e.aprvlLineType === aprvlLineType.APPROVAL && !isEmpty(e.reason),
    //   );
    // },
  },
  mounted() {
    //this.getApprovalLineList(commConst.ACTOR_FLAG.APPROVAL);
    //this.fetchAndInitStoredDraftData();
    //this.updateRead();

    this.initDraftData();
  },
  methods: {
    async initDraftData() {
      const result = await ApiService.shared.getData(
        `${this.$apiPath.SETTLEMENT}/${this.$route.params.id}`,
      );
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      console.log("settlementDatainitData", data);
      this.settlementData.setData(data);
    },
    async fetchAndInitStoredDraftData() {
      // const data = await this.fetchStoredSettlementData(this.docNo);
      const result = await ApiService.shared.getData(
        `${this.$apiPath.SETTLEMENT_DETAIL}/${this.$route.params.id}`,
      );

      const test = { data: result.data };
      //this.settlementData.setData()
      //this.settlementData.initFromFetchData(test);
      // this.settlementData.deptPathName = await this.fetchDeptPathNameByDeptCode(
      //   result.data.drftDeptCode,
      // );
      //this.approvalLine = result.data.aprvlLineList;
      this.regDate = result.data.regDate || "";
    },

    getViewerList() {
      this.viewerList = this.approvalLine.filter((e) => e.aprvlLineType === aprvlLineType.VIEWER);

      this.memberList = [];
      let memberList = this.memberList.concat(this.viewerList);
      memberList.forEach((viewer) => {
        viewer.typeMe = true;
      });
      this.memberList = memberList;
    },
    getAddApproverList() {
      const approvalList = this.approvalLine.filter(
        (approver) =>
          approver.aprvlLineType === aprvlLineType.DRAFT ||
          approver.aprvlLineType === aprvlLineType.APPROVAL,
      );

      this.memberList = [];
      let memberList = this.memberList.concat(approvalList);
      memberList.forEach((member, index) => {
        // 추가한 결재자가 아닌경우
        if (!member.isTemp) {
          // 기존 결재라인에 중복제거
          const memberOverlayList = memberList.filter((item) => item.empNo == member.empNo);
          if (memberOverlayList.length > 1) {
            memberList = memberList.filter(
              (item, index2) => item.empNo != member.empNo || index == index2,
            );
          }
          // 기존 결재자 타입구분
          member.typeMe = true;
        }
      });
      this.memberList = memberList;

      // 중간 추가의 경우 - memberList에서의 (뒤에서부터)index값
      const nextApprover = memberList.find(
        (item) => item.aprvlStatus === approvalStatus.BEFORE_PAYMENT && !item.isTemp,
      );
      const nextApproverIndex = memberList.indexOf(nextApprover);
      this.spliceAddIndex = nextApprover ? (memberList.length - nextApproverIndex) * -1 : null;

      // 중간 추가의 경우 - approvalLine에서의 (뒤에서부터)index값
      const nextApproverFromAll = this.approvalLine.find(
        (item) => item.aprvlStatus === approvalStatus.BEFORE_PAYMENT && !item.isTemp,
      );
      const nextApproverIndexFromAll = this.approvalLine.indexOf(nextApproverFromAll);
      this.spliceAddIndexFromAll = nextApproverFromAll
        ? (this.approvalLine.length - nextApproverIndexFromAll) * -1
        : null;
    },
    popDeleteViewer(viewer) {
      this.deletedViewer = viewer;
      this._showLayer({ type: "confirm", content: "열람자 취소하시겠습니까?" }, this.deleteViewer);
    },
    popDeleteapprover(approver) {
      this.addApproverList = this.addApproverList.filter((item) => item.empNo != approver.empNo);
      this.memberList = this.memberList.filter((member) => member.typeMe);
      this.memberList = this.memberList.concat(this.addApproverList);
      this.approvalLine = this.approvalLine.filter((approver) => !approver.isTemp);
      this.addApproverList.forEach((addItem) => {
        addItem.aprvlLineType = aprvlLineType.APPROVAL;
        addItem.aprvlStatus = approvalStatus.BEFORE_PAYMENT;
        // 중간 추가가 아닌경우
        if (this.spliceAddIndexFromAll === null) {
          this.approvalLine.push(addItem);
          // 중간 추가의 경우
        } else {
          this.approvalLine.splice(this.spliceAddIndexFromAll, 0, addItem);
        }
      });
    },
    async deleteViewer() {
      const res = await ApiService.shared.delete(
        `${this.$apiPath.APRVL_LINE}/${targetGubun.STANDARD}/${this.docNo}/${this.deletedViewer.empNo}`,
      );

      if (this.checkNormalResponse(res)) {
        const deletedIndex = this.approvalLine.findIndex(
          (e) => e.aprvlLineType === aprvlLineType.VIEWER && e.empNo === this.deletedViewer.empNo,
        );
        if (deletedIndex > -1) {
          this.approvalLine.splice(deletedIndex, 1);
        }
        this._showToast({ content: "열람자 취소 되었습니다." });
      }
    },
    searchOrganization(gubun) {
      let layer = {};
      layer.type = "popup";
      layer.class = "inner_structure_layer";
      layer.header = "조직도";
      layer.componentFile = "common/CommPopOrganization.vue";
      layer.props = {
        orgType: 2, // 1:single select, 2: multi select
        alertMsg: gubun === "viewer" ? "열람자를 선택해주세요." : "결재자를 선택해주세요.",
        spliceAddIndex: this.spliceAddIndex, // 중간추가시 추가할 항목의 뒤에서 부터 Index값
        employeeList: this.memberList,
      };
      // 열람자
      if (gubun === "viewer") {
        this._showLayer(layer, this.attachReferenceViewer);
        // 결재자
      } else {
        this._showLayer(layer, this.attachReferenceApprover);
      }
    },
    // 구매요청 열람자 기능 퍼옴
    async attachReferenceViewer(employeeList) {
      if (isEmpty(employeeList)) {
        return;
      }
      let errorMsg = "";

      employeeList = employeeList.filter((item) => !item.typeMe);
      if (employeeList.length > 0) {
        for (const e of employeeList) {
          const res = await ApiService.shared.post(this.$apiPath.APRVL_LINE, {
            empNo: e.empNo,
            aprvlStatus: approvalStatus.APPROVAL_PAYMENT,
            aprvlLineType: aprvlLineType.VIEWER,
            targetGubun: targetGubun.STANDARD,
            targetPkList: [this.docNo],
          });
          if (parseInt(res.code) !== 200) {
            this.errorMsg = res.text || "열람자 추가 실패했습니다. 관리자에게 문의하세요";
          }
          this.approvalLine.push(Object.assign(e, { aprvlLineType: aprvlLineType.VIEWER }));
        }
        this.memberList = employeeList;
        this._showToast({ content: "열람자 추가 되었습니다" });
      }

      // 에러발생시
      if (errorMsg != "") {
        this.layerAfterAlertMsg = errorMsg;
      }
    },
    attachReferenceApprover(employeeList) {
      if (isEmpty(employeeList)) {
        return;
      }

      const addEmployeeList = employeeList.filter((item) => !item.typeMe);

      // 추가결재자에 변경이 있을 경우 확인
      this.addApproverList.forEach((orginItem) => {
        // 추가결재자 중에 이번에 추가한 구성원과 다른 구성원이 있을 경우
        addEmployeeList.forEach((addItem) => {
          const isOverlay = addItem.empNo == orginItem.empNo;
          if (!isOverlay) {
            console.log(orginItem.accountId);
          }
        });
      });
      // 추가결재자만 추가결재자 적용
      this.addApproverList = addEmployeeList;
      // 조직도팝업은 전체 결재라인 결재자 적용
      this.memberList = employeeList;
      this.approvalLine = this.approvalLine.filter((approver) => !approver.isTemp);

      addEmployeeList.forEach((addItem) => {
        addItem.aprvlLineType = aprvlLineType.APPROVAL;
        addItem.aprvlLineTypeName = "결재자";
        addItem.aprvlStatus = approvalStatus.BEFORE_PAYMENT;
        addItem.aprvlStatusName = "결제전";
        addItem.isTemp = true;
        // 중간 추가가 아닌경우
        if (this.spliceAddIndexFromAll == null) {
          this.approvalLine.push(addItem);
          // 중간 추가의 경우
        } else {
          this.approvalLine.splice(this.spliceAddIndexFromAll, 0, addItem);
        }
      });

      // 변경사항 있을때만 토스트
      if (addEmployeeList.length > 0) {
        this._showToast({
          content: "등록된 추가 결재자는 승인 시 적용됩니다.",
        });
      }
    },
    // 전표내역 열기
    onClickPopStatementVoucher() {
      if (this.isInfraStatement) {
        // 인프라정산서 전표내역 열기
        this.isPopInfraVoucher = true;
      } else {
        // 지출정산서 전표내역 열기
        this.isPopStatementVoucher = true;
      }
    },
    // 지출정산서 전표내역 닫기
    onClickClosePopStatementVoucher() {
      this.isPopStatementVoucher = false;
    },
    // 지출정산서 전표내역 생성완료
    onClickCompletePopStatementVoucher() {
      this.isPopStatementVoucher = false;
    },
    // 인프라정산서 전표내역 닫기
    onClickClosePopInfraVoucher() {
      this.isPopInfraVoucher = false;
    },
    // 인프라정산서 전표내역 생성완료
    onClickCompletePopInfraVoucher() {
      this.isPopInfraVoucher = false;
    },
    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        targetPkList: [this.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      // console.log('params', params);

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    docFlagToCode(flag) {
      // - 진행중 문서 : docStatus 'I'
      // - 완료된 문서 : docStatus 'C'
      // - 반려된 문서 : docStatus 'J'
      //
      // - 결재전 문서 : aprvlStatus 'W'
      // - 수신된 문서 : aprvlStatus 'R'
      // - 진행중 문서 : aprvlStatus 'A'
      // - 완료된 문서 : docStatus 'C', aprvlStatus 'ALL'
      // - 반려된 문서 : docStatus 'J', aprvlStatus 'ALL'
      //
      // - 진행중 문서 : docStatus 'I', aprvlStatus 'N'
      // - 처리된 문서 : docStatus 'C', aprvlStatus 'N'
      switch (flag) {
        case "DI":
          return { docStatus: "I", aprvlStatus: "" };
        case "DC":
          return { docStatus: "C", aprvlStatus: "" };
        case "DR":
          return { docStatus: "J", aprvlStatus: "" };
        case "AB":
          return { docStatus: "", aprvlStatus: "W" };
        case "AE":
          return { docStatus: "", aprvlStatus: "R" };
        case "AEW":
          return { docStatus: "", aprvlStatus: "R" };
        case "AI":
          return { docStatus: "", aprvlStatus: "A" };
        case "AC":
          return { docStatus: "C", aprvlStatus: "ALL" };
        case "AR":
          return { docStatus: "J", aprvlStatus: "ALL" };
        case "RI":
          return { docStatus: "I", aprvlStatus: "N" };
        case "RC":
          return { docStatus: "C", aprvlStatus: "N" };
        default:
          return { docStatus: "", aprvlStatus: "" };
      }
    },
  },
};

export default StatementDetailMixin;
