import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

class PurchaseRequestWfModel {
  constructor() {
    this.name = ""; // 구매요청자명
    // this.regDate = ''; // 구매요청등록일
    this.requestCid = ""; // 구매요청 기안번호
    this.title = ""; // 제목
    this.assetType = "";
    this.currency = "KRW";
    this.price = 0;
    this.docStatus = "";
    this.aprvlStatus = "";
  }

  setData(obj) {
    if (obj.name) this.name = obj.name;
    //if(obj.regDate) this.regDate = stringToDateWithTime(obj.regDate);
    if (obj.requestCid) this.requestCid = obj.requestCid;
    if (obj.title) this.title = obj.title;
    if (obj.assetType) this.assetType = obj.assetType;
    if (obj.currency) this.currency = obj.currency;
    if (obj.price) this.price = obj.price;
    if (obj.docStatus) this.docStatus = obj.docStatus;
    if (obj.aprvlStatus) this.aprvlStatus = obj.aprvlStatus;
  }

  getData() {
    let obj = {
      name: this.name,
      requestCid: this.requestCid,
      title: this.title,
      assetType: this.assetType,
      currency: this.currency,
      price: this.price,
      docStatus: this.docStatus,
      aprvlStatus: this.aprvlStatus,
      // regDate: dateToStringWithTime(this.regDate)
    };

    return obj;
  }
}

export default PurchaseRequestWfModel;
